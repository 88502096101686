html {
  font-family: 'Open Sans', sans-serif;
}

body {
  font-size: 13px;
  margin: 0;
}

a,
a:visited {
  text-decoration: none;
  color: white;
}

.marker-link a {
  text-decoration: none;
  color: #ff0044;
}

#app {
  position: relative;
}

.clearfix:after {
  content: '';
  display: block;
  clear: both;
}

/* As we are using material-ui with black theme Chrome autofill yellowish
  highlight breaks the visuals in forms, thus we make it invisible with
  below snippet, more in http://stackoverflow.com/questions/2781549/removing-input-background-colour-for-chrome-autocomplete
*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition: 'color 9999s ease-out, background-color 9999s ease-out';
  -webkit-transition-delay: 9999s;
}

/*
 * Custom flexbox stuff
 */

/* xs */
@media only screen and (max-width: 47.99999999999em) {
  .xs-hidden {
    display: none !important;
    visibility: hidden !important;
  }
  .xs-overflow {
    overflow-x: hidden !important;
    overflow-y: auto !important;
  }
}

/* sm */
@media only screen and (min-width: 48em) and (max-width: 63.99999999999em) {
  .sm-hidden {
    display: none !important;
    visibility: hidden !important;
  }
  .sm-overflow {
    overflow-x: hidden !important;
    overflow-y: auto !important;
  }
}

/* md */
@media only screen and (min-width: 64em) and (max-width: 74.99999999999em) {
  .md-hidden {
    display: none !important;
    visibility: hidden !important;
  }
  .md-overflow {
    overflow-x: hidden !important;
    overflow-y: auto !important;
  }
}

/* lg */
@media only screen and (min-width: 75em) {
  .lg-hidden {
    display: none !important;
    visibility: hidden !important;
  }
  .lg-overflow {
    overflow-x: hidden !important;
    overflow-y: auto !important;
  }
}
